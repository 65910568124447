import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "bootstrap-css-only/css/bootstrap.css";
import "mdbreact/dist/css/mdb.css";
import "./assets/sass/app.scss";
import store from "./store";
import {
  Home,
  CkanSearch,
  Portal,
  UserProvider,
  Maintenance,
} from "./containers";

import { Page } from "./components";

const App = () => (
  <Provider store={store}>
    <UserProvider>
      <Router>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/portals" component={CkanSearch} />
          <Route path="/portal/not-found" component={Page.NotFound.Portal} />
          <Route path="/portal/:id" component={Portal} />
          <Route path="/maintenance" component={Maintenance} />
          <Route path="/contact" component={Page.ContactUs} />
          <Route path="/about" component={Page.About} />
          <Route component={Maintenance} />
        </Switch>
      </Router>
    </UserProvider>
  </Provider>
);

export default App;
