import React from 'react';
import { HashLink } from 'react-router-hash-link';
import moment from 'moment';
import logo from '../../assets/images/Logo-02@2x.png';
import { AccessCheck } from '../../containers';
import { Element } from '..';

const ProfileBanner = ({
  ckan,
  updateHandler,
  revisions,
  onRevisionSelect,
  removeRevision,
}) => {
  const [isUpdating, setUpdating] = React.useState(false);

  const updatePortal = React.useCallback(() => {
    setUpdating(true);
    updateHandler();
  }, [setUpdating, updateHandler]);

  revisions.sort(
    ({ CreatedAt: left }, { CreatedAt: right }) =>
      new Date(right) - new Date(left)
  );

  let url;
  const lastUpdate = moment.utc(ckan.data.CreatedAt);
  try {
    url = new URL(ckan.data.Href);
  } catch (e) {
    url = new URL(window.location);
  }

  return (
    <div className="col-md-12 col-lg-12">
      <div className="profile-banner">
        <div className="profile-actions">
          <div className="row">
            <div className="col-lg-5 col-md-6 col-sm-12">
              <div className=" portal-title-block">
                <h1 className="portal-title">
                  {ckan.data.SiteInfo !== undefined &&
                  ckan.data.SiteInfo.site_title !== undefined
                    ? ckan.data.SiteInfo.site_title
                    : url.host}
                </h1>
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href={url}
                  className="portal-link"
                >
                  {url.host}
                </a>
              </div>
            </div>
            <div className="col-lg-7 col-md-6 col-sm-12">
              <div className="actions">
                <span className="title">
                  <strong>Last Update: </strong>
                  <AccessCheck anonimousOnly={true}>
                    {lastUpdate.utc().format('DD MMMM, YYYY. HH:mm')} (UTC)
                  </AccessCheck>
                  <AccessCheck userRequired={true}>
                    <select
                      className="form-control"
                      onChange={(e) =>
                        onRevisionSelect(
                          revisions.find(
                            ({ CreatedAt }) => CreatedAt === e.target.value
                          )
                        )
                      }
                      value={ckan.data.CreatedAt}
                    >
                      {revisions.map(({ CreatedAt: ca }, idx) => (
                        <option key={ca} value={ca}>
                          {moment.utc(ca).format('DD MMMM, YYYY. HH:mm')} (UTC)
                          {idx === 0 && ' Current'}
                        </option>
                      ))}
                    </select>
                  </AccessCheck>
                </span>
                <div>
                  {moment().startOf('second').isAfter(lastUpdate) &&
                    ((revisions.lenght &&
                      revisions[0].CreatedAt === ckan.data.CreatedAt) ||
                      true) && (
                      <AccessCheck userRequired={true}>
                        <button
                          className="btn"
                          disabled={isUpdating}
                          hidden={isUpdating}
                          onClick={updatePortal}
                        >
                          Update Portal
                        </button>
                      </AccessCheck>
                    )}
                    <AccessCheck userRequired={true}>
                      <button
                        className="btn"
                        disabled={isUpdating}
                        hidden={isUpdating}
                        onClick={removeRevision}
                      >
                        Remove Revision
                      </button>
                    </AccessCheck>

                  <HashLink smooth className="btn" to="/#over-portal-search">
                    Go to Map
                  </HashLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="banner-footer">
          <div className="row">
            <div className="col-lg-8 col-md-7 col-sm-12">
              <div className="portal-logo float-md-left">
                <img src={logo} alt="" />
              </div>
              <div className="portal-text text-center text-md-left">
                {(ckan.data.SiteInfo || {}).site_description || ''}
              </div>
              {revisions.length > 0 &&
                revisions[0].CreatedAt !== ckan.data.CreatedAt && (
                  <h3 className="portal-text text-danger">Obsolete revision</h3>
                )}
            </div>
            <div className="col-lg-4 col-md-5 col-sm-12">
              <ul className="counters pull-right">
                <li>
                  <p>
                    <span>{ckan.data.UsersNumber}</span>
                  </p>
                  <p>Users</p>
                </li>
                <li>
                  <p>
                    <span>{ckan.data.DatasetsNumber}</span>
                  </p>
                  <p>Datasets</p>
                </li>
                <li>
                  <p>
                    <span>{ckan.data.ResourcesNumber}</span>
                  </p>
                  <p>Resources</p>
                </li>
              </ul>
            </div>
          </div>
          {isUpdating && (
            <div className="row">
              <div className="col-md-12">
                <Element.Alert.UpdateIsScheduled />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileBanner;
